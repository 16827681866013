// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-free/scss/solid.scss';
@import '@fortawesome/fontawesome-free/scss/regular.scss';
@import '@fortawesome/fontawesome-free/scss/brands.scss';

.team_link_icon {
    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
    text-decoration: none;
}

.materialTitle {
    margin-bottom: 10px;
}

.footerSocialIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 3rem;
    height: 3rem;
}

.videoHeader {
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    background-color: black;
    height: 70vh;
    min-height: 25rem;
    width: 100%;
    overflow: hidden;

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: black;
        opacity: 0.5;
        z-index: 1;
    }

    video {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 75vh;
        width: auto;
        height: auto;
        z-index: 0;
        -ms-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;
        z-index: 2;

        color: white;

        h1 {
            font-size: 3.5rem;
        }

        p {
            margin-bottom: .5vh;
            font-size: 1.5rem;
        }

        @media screen and (max-width: 700px) {
            h1 {
                font-size: 2.2rem;
            }
            p {
                font-size: 1.1rem;
            }
        }

        @media only screen and (max-width: 400px) {
            h1 {
                font-size: 1.5rem;
            }
            p {
                font-size: 1.1rem;
            }
        }
    }
}

@media (pointer: coarse) and (hover: none) {
    .videoHeader {
        background: url('/images/no_video.webp') black no-repeat center center scroll;
    }

    .videoHeader video {
        display: none;
    }
}

.rawBtn {
    position: relative;
    display: inline-block;
    text-align: center;
    font-size: 1rem;
    font-weight: var(--font-weight);
    font-family: var(--font-family), sans-serif;
    color: black;
    fill: black;
    background: whitesmoke;
    background: linear-gradient(to right, white 0%, lightgray 100%);
    text-decoration: none;
    padding: 0.75em 1.75em;
    border-radius: var(--el-border-radius-min);
    outline: none;
    border: none;
    cursor: pointer;
    overflow: hidden;
    transition: all var(--ripple-time);

    &:hover {
        color: var(black);
        fill: var(black);

        background: #dadada;
        background: linear-gradient(to right, #dadada 0%, #b9b9b9 100%);
    }
}

.rawButton {
    background: whitesmoke;
    color: black;
}

.rawButton:hover {
    color: white;
}

.catalogue_download {
    color: black;

    text-align: center;
    text-decoration: none;
    font-size: 1.2rem;

    &:hover {
        color: #00b4c1;
        text-decoration: none;
    }
}

.centeredImage {
    border: 1px solid var(--border-color);
    background-color: var(--primary-bg-color);
    border-radius: var(--el-border-radius);
    overflow: hidden;

    width: 20rem;

    img {
        margin: 0;
    }
}

.selectedColorFilter {
    border: 3px solid black;
}

.medium-width {
    width: 60%;
}

.material-search {
    width: 50%;
    margin-bottom: 0 !important;
}

.centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.inTextImage {
    border-radius: 1vh;
    width: 90%;
    margin-bottom: 2rem;
}

//smartphone
@media (min-width: 300px) and (max-width: 1024px) {
    .medium-width {
        width: 80%;
    }

    .material-search {
        width: 90%;
        margin-bottom: 0 !important;
    }
}

.lowerEmotion {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    padding: 0 1rem;

    width: 100%;
    height: 20%;

    background-color: white;

    text-align: center;
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);

    border-bottom-left-radius: var(--el-border-radius);
    border-bottom-right-radius: var(--el-border-radius);

    .emotionImage {
        height: 3.5rem;
        width: 3.5rem;
        border-radius: .25rem;
    }

    span {
        width: 100%;
        padding: 0 5%;

        color: black;
        font-size: 1.5vh;
        font-weight: 500;
    }
}

.specialItem {
    border-radius: 10px;
    border: 1px solid black;
    overflow: hidden;
}

.specialText {
    padding: 2rem 4rem;
}

.specialHover {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    color: white;
    opacity: .8;

    z-index: 99999999 !important;
}

.specialHover:hover {
    cursor: pointer;
    opacity: 1;
}

.specialRight {
    right: .5rem;;
}

.specialLeft {
    left: .5rem;;
}

.canvas {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background: #000;
    z-index: 5;
}

.teamName {
    font-size: 1rem;
    margin-bottom: 0.3125rem;
    text-transform: uppercase;
    color: black;
}

.flickity-button-icon {
    fill: currentColor;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.quoteLeft {
    position: absolute;

    left: .5rem;
    top: 0rem;

    margin-bottom: 1rem;

    height: 1rem;
    width: 1rem;
}

.quoteRight {
    position: absolute;

    right: .5rem;
    bottom: 0rem;
    transform: rotate(180deg);

    margin-bottom: 1rem;

    height: 1rem;
    width: 1rem;
}

.machineFrame {
    display: flex;
    flex-direction: column;

    margin-bottom: 1rem;
    padding: unset !important;

    border-radius: 1rem;
    overflow: hidden;

    border: 1px solid var(--border-color);

    /* Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        .machineImage {
            height: 300px !important;
        }
        .machineFooter {
            height: 75px;
        }
    }

    /* Smartphones (landscape) ----------- */
    @media only screen and (min-width: 321px) {
        .machineImage {
            height: 300px !important;
        }
        .machineFooter {
            height: 75px;
        }
    }

    /* Smartphones (portrait) ----------- */
    @media only screen and (max-width: 320px) {
        .machineImage {
            height: 300px !important;
        }
        .machineFooter {
            height: 75px;
        }
    }

    /* iPads (portrait and landscape) ----------- */
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        .machineImage {
            height: 500px !important;
        }
        .machineFooter {
            height: 100px;
        }
    }

    /* iPads (landscape) ----------- */
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
        .machineImage {
            height: 500px !important;
        }
        .machineFooter {
            height: 100px;
        }
    }

    /* iPads (portrait) ----------- */
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        .machineImage {
            height: 600px !important;
        }
        .machineFooter {
            height: 100px;
        }
    }

    /* Desktops and laptops ----------- */
    @media only screen and (min-width: 1224px) {
        .machineImage {
            height: 700px !important;
        }

        .machineFooter {
            height: 100px;
        }
    }


    .machineImage {
        display: flex;
        align-items: center;
        align-content: center;

        width: 100%;

        overflow: hidden;

        .custom-carusell {
            min-width: 100%;
            min-height: 100%;

            margin: unset !important;
        }
    }

    .machineFooter {
        display: flex;
        align-items: center;
        align-content: center;
        flex-direction: row;

        height: 75px;
        width: 100%;

        padding-left: 2rem;

        font-weight: 600;
        font-size: 1.2rem;
        z-index: 999 !important;

        .machineText {
            padding-left: 1rem;
            text-align: start;
        }
    }
}

.gallery-item-custom {
    position: relative;
    margin: 0;
    display: block;
    width: 100%;
    height: 400px;
    text-decoration: none;
    color: var(--primary-color);
    background-color: var(--primary-bg-color);
    overflow: hidden;
}


.emotionImageHover {
    height: 8rem !important;
    width: 8rem !important;
    border-radius: .5rem;

    z-index: 999 !important;
}

.selectorCol {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 .5rem !important;
    margin-top: 1rem;

    .selectorFrame {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;

        img {
            width: 100%;
            border-radius: .625rem;

            opacity: .5;

            &:hover {
                cursor: pointer;
                opacity: 1;
            }
        }

        .selected {
            opacity: 1;
        }
    }
}

.hoverToBeBlue {
    &:hover {
        cursor: pointer;
        color: #009cb4 !important;

        i {
            color: #009cb4 !important;
        }
    }
}

.heading-center {
    .h3 {
        .i {
            font-size: 1rem;
        }
    }
}

/*
.card-image img {
   object-fit: unset !important;
}
*/

.pcth-item img {
    object-fit: contain !important;
}

.is-fullscreen {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: hsl(0 0% 0% / 90%);
    padding-bottom: 35px;
    z-index: 100000;

    .flickity-viewport {
        .flickity-slider {
            .card-image {
                background-color: rgba(0, 0, 0, 0.15);
                overflow: unset !important;

                img {
                    //object-fit: unset;

                    top: 50vh;
                    left: 50vw;
                    transform: translate(-50%, -50%);

                    max-width: 80vw !important;
                    max-height: 80vh !important;

                    width: unset;
                    height: unset;
                }
            }
        }
    }
}

.invisibleLink {
    color: var(--text-primary);
    text-decoration: none;
    font-weight: 500;

    &:hover {
        color: var(--text-primary);
    }
}

.parent {
    position: relative;
    overflow: hidden;
}

.ribbon {
    position: absolute;
    clip-path: inset(0 -100%);
    inset: 20px 20px auto auto;
    transform-origin: 0 0;
    transform: translate(50%, -130%) rotate(31deg);

    background-color: #ff5f5f;
    padding: 0 100px;

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 10;

    font-weight: bolder;
    color: white;
    font-size: 30px;
}

.offers {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    position: fixed;
    left: 0;
    bottom: 100px;

    transform: translateX(-77%);


    padding: 10px 10px;

    background-color: #ff5f5f;
    color: white;

    z-index: 5000000;

    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;

    transition: all 300ms;
}

.offers:hover {
    transform: translateX(0);
    transition: all 300ms;
}

.offers a {
    color: white;
    font-weight: bolder;
    text-decoration: none;
    text-align: center;
}

.offers a:hover {
    cursor: pointer;
    text-decoration: underline;
}

.offers i:hover {
    cursor: pointer;
    color: #d0d0d0;
}

.companyInputHidden {
    height: 0;
    overflow: hidden;
}

#holiday-info {
    position: fixed;
    top: 0;
    left: 0;
    height: 0;
    width: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;

    z-index: 999999999999;
    background-color: rgba(0, 0, 0, 0.25);
}

#holiday-info.opened {
    width: 100vw;
    height: 100vh;
}

.holiday-info-inner {
    padding: 2rem;
    border-radius: 1rem;
    background-color: white;
    max-width: 90%;
}

@media screen and (min-width: 480px) {
    .holiday-info-inner {
        width: 90vw;
    }
}
@media screen and (min-width: 769px) {
    .holiday-info-inner {
        width: 70vw;
    }
}
@media screen and (min-width: 1201px) {
    .holiday-info-inner {
        width: 50vw;
    }
}

.holiday-info-header {
    display: flex;
    align-items: center;
    justify-content: end;
    flex-direction: row;
    width: 100%;

    #close-holiday-info {
        padding: 10px;
        border-radius: 1rem;

        &:hover {
            cursor: pointer;
            background-color: lightgray;
        }
    }
}


.holiday-info-image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    margin-bottom: 4rem;

    img {
        width: 100%;
    }
}

@media screen and (min-width: 480px) {
    .holiday-info-image {
        img {
            width: 80%;
        }
    }
}
@media screen and (min-width: 769px) {
    .holiday-info-image {
        img {
            width: 60%;
        }
    }
}

.holiday-info-desc{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    p {
        width: 90%;
        text-align: center;
    }
}
